import Swiper from 'swiper';

export default function Hotspots(el, {
    showCoords = false,
}) {
    const figure = el.querySelector('figure');
    const image = el.querySelector('figure > img');
    const coords = el.querySelector('figure > span');
    const labels = el.querySelectorAll('label');
    const floatingDetails = [...labels].map(l => l.nextElementSibling).filter(d => d);
    const inputs = el.querySelectorAll('input[type="radio"]');
    const carousel = el.querySelector('figure + div');
    const swiper = new Swiper(carousel, { // eslint-disable-line no-new
        slidesPerView: 'auto',
    });

    function boundFloatingDetails() {
        const { innerWidth: width } = window;

        floatingDetails.forEach(d => {
            const { left, right } = d.getBoundingClientRect();

            if (left < 0) {
                d.style.right = `${left - 5}px`;
            } else if (right > width) {
                d.style.left = `${width - right - 5}px`;
            }
        });
    }

    window.addEventListener('resize', boundFloatingDetails);
    labels.forEach((l, i) => {
        l.onclick = e => {
            i %= inputs.length; // 2 labels per input

            if (inputs[i].checked) {
                e.preventDefault();
                inputs[i].checked = false;
            }

            swiper.slideTo(i);
        };
    });
    swiper.on('slideChange', ({ activeIndex: i }) => {
        inputs[i].checked = true;
    });

    if (showCoords) {
        figure.addEventListener('mousemove', ({ clientX, clientY }) => {
            const { top, left, width, height } = image.getBoundingClientRect();
            const x = clientX - left;
            const y = clientY - top;
            const pX = Math.round((x / width) * 100);
            const pY = Math.round((y / height) * 100);

            coords.style.left = `${x + 5}px`;
            coords.style.top = `${y + 5}px`;
            coords.textContent = `X: ${Math.round(pX)}%, Y: ${Math.round(pY)}%`;
        });
        figure.addEventListener('mouseleave', () => { coords.textContent = ''; });
    }

    boundFloatingDetails();
}
