const ANIMATION_DURATION = 300;

const setListHeight = (list, items) => {
    const height = [...items].reduce((acc, { offsetHeight: h }) => acc + h, 0);

    list.closest('div').style.height = `${height}px`;
};

export default function ScrollingSidekick(el) {
    const list = el.querySelector('ul');
    const items = list.querySelectorAll('li');

    window.addEventListener('resize', () => {
        setListHeight(list, items);
    }, { passive: true });

    setListHeight(list, items);
    items.forEach(item => { list.appendChild(item.cloneNode(true)); });
    setInterval(() => {
        const first = list.querySelector('li');

        list.style.transition = `transform ${ANIMATION_DURATION}ms ease-in-out`;
        list.style.transform = `translateY(-${first.offsetHeight}px)`;
        setTimeout(() => {
            list.removeChild(first);
            list.appendChild(first);
            list.style.transition = 'none';
            list.style.transform = 'translateY(0)';
        }, ANIMATION_DURATION);
    }, 3000);
}
