export default function Header(el, {
    sentinelHandle,
    activeClass = 'is-active',
    invertedClass = 'is-inverted',
    fixedClass = 'is-fixed',
}) {
    const bgSections = document.querySelectorAll('section[data-bg-color]');
    const form = el.querySelector('form');
    const sentinel = el.querySelector(sentinelHandle);
    const header = sentinel.nextElementSibling;
    const menus = el.querySelectorAll('a + ul, button + ul');
    const menuToggles = [...menus].map(m => m.previousElementSibling);
    const menuBacks = [...menus].map(m => m.querySelector('button'));
    const mobileToggle = el.querySelector('nav + button');

    function setHeaderHeight() {
        document.documentElement.style.setProperty('--h-header', `${header.offsetHeight}px`);
    }
    function setBgColor() {
        const activeSection = [...bgSections]
            .reverse()
            .find(section => section.getBoundingClientRect().top < el.offsetHeight / 2);
        const inverted = window.scrollY === 0
            || !el.classList.contains(fixedClass)
            || (activeSection && activeSection.dataset.bgColor === 'dark');

        el.classList.toggle(invertedClass, inverted);
    }

    window.addEventListener('resize', setHeaderHeight, { passive: true });
    window.addEventListener('scroll', setBgColor, { passive: true });
    if (form) {
        form.onsubmit = e => {
            e.preventDefault();

            fetch('/', {
                method: 'POST',
                headers: { Accept: 'application/json' },
                body: new FormData(form),
            }).then(({ status }) => {
                if (status === 200) {
                    form.parentElement.remove();
                }
            });
        };
    }
    menuToggles.forEach((t, i) => {
        t.onclick = e => {
            if (el.classList.contains(activeClass)) {
                e.preventDefault();
            }

            menus.forEach((m, ii) => {
                m.classList.toggle(activeClass, i === ii);
            });
        };
    });
    menuBacks.forEach((b, i) => {
        b.onclick = () => {
            menus[i].classList.remove(activeClass);
        };
    });
    mobileToggle.onclick = () => {
        if (!el.classList.toggle(activeClass)) {
            menus.forEach(m => m.classList.remove(activeClass));
        }
    };

    setTimeout(() => {
        setHeaderHeight();
        setBgColor();
    }, 0);

    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
        el.classList.toggle(fixedClass, !isIntersecting);
    });
    observer.observe(sentinel);
}
