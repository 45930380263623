const getJob = ({
    title,
    displayUrl,
    publishedDate,
    hiringDepartment,
    jobLocation,
}) => {
    const published = new Date(publishedDate);

    return `<li>
        <a href="${displayUrl}" target="_blank">
            <h3>${title}</h3>
            <div>
                <p>${published.getMonth() + 1}/${published.getDate()}/${published.getFullYear()}
                ${hiringDepartment ? `<p>${hiringDepartment}</p>` : ''}
                <p>${jobLocation.locationDisplayName}</p>
            </div>
        </a>
    </li>`;
};

export default function PaylocityCareers(el, {
    feedId,
}) {
    const header = el.querySelector('header');
    const url = `https://recruiting.paylocity.com/recruiting/v2/api/feed/jobs/${feedId}`;

    fetch(url)
        .then(response => response.json())
        .then(response => {
            if (response.jobs.length === 0) {
                return;
            }

            const jobsMarkup = response.jobs.map(job => getJob(job)).join('');

            header.insertAdjacentHTML('afterend', `<ul>${jobsMarkup}</ul>`);
        });
}
