export default function HoverCallouts(el, {
    activeClass = 'is-active',
}) {
    const callouts = el.querySelectorAll('li');
    const images = el.querySelectorAll('img');
    const setActiveItem = i => {
        callouts.forEach((cc, ii) => { cc.classList.toggle(activeClass, i === ii); });
        images.forEach((img, ii) => { img.classList.toggle(activeClass, i === ii); });
    };

    callouts.forEach((c, i) => {
        c.onclick = () => { setActiveItem(i); };
        c.onmouseover = () => { setActiveItem(i); };
    });

    setActiveItem(0);
}
