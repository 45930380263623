import { cycleElementStates, matchHeights } from '../utilities';

export default function TestimonialCards(el) {
    const cards = el.querySelectorAll('li');

    let cardStates = cycleElementStates(cards, [
        ['at-bat', 0],
        ['on-deck', 1],
        ['in-the-hole', 2],
    ].filter((_, i) => i < cards.length)); // Limit states by number of cards

    window.addEventListener('resize', () => {
        matchHeights(cards);
    }, { passive: true });

    matchHeights(cards);
    setInterval(() => {
        cardStates = cycleElementStates(cards, cardStates);
    }, 7000);
}
