import { loadScript } from '../utilities';

export default function PaylocityCareers(el, {
    feedId,
    elementId,
}) {
    loadScript('https://jobs.ourcareerpages.com/Resources/js/ccp_widget_support.js', () => {
        bdhr.generateListing({ // eslint-disable-line no-undef
            CCPCode: feedId,
            ElementID: elementId,
            ShowCustomContent: false,
        });
    });
}
