import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default function FeaturedVideos(el) {
    const carousel = el.querySelector('div');
    const [prev, next] = el.querySelectorAll('nav > button');

    new Swiper(carousel, { // eslint-disable-line no-new
        modules: [Navigation],
        navigation: {
            prevEl: prev,
            nextEl: next,
        },
        loop: true,
        slidesPerView: 'auto',
    });
}
