import { cycleElementStates } from '../utilities';

export default function LinksHero(el, {
    countHandle,
    speed,
}) {
    const images = el.querySelectorAll('aside > div');
    const buttons = el.querySelectorAll(`ul > li:not(${countHandle}) > button`);
    const count = el.querySelector(`${countHandle} > em`);

    let states = cycleElementStates([...images, ...buttons], [
        ['is-active', images.length - 1], // Start at last image
        ['is-active', (images.length + buttons.length) - 1], // Start at last button
    ]);
    count.innerHTML = (states[0][1] % images.length) + 1;

    setInterval(() => {
        states = cycleElementStates([...images, ...buttons], states);
        count.innerHTML = (states[0][1] % images.length) + 1;
    }, parseInt(speed, 10) * 1000);
}
