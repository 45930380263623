import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default function CardsCarousel(el) {
    const carousel = el.querySelector('header + div');
    const [prev, next] = el.querySelectorAll('nav > button');

    const slider = new Swiper(carousel, { // eslint-disable-line no-new
        modules: [Navigation],
        navigation: {
            prevEl: prev,
            nextEl: next,
        },
        loop: true,
        slidesPerGroup: 1,
        slidesPerView: 'auto',
    });

    // Hack to ensure overflow slides are visible
    slider.slideTo(slider.slides.length / 2, 0);
}
