import { cycleElementStates, matchHeights } from '../utilities';

export default function FeaturedTestimonials(el, {
    activeClass,
}) {
    const quotes = el.querySelectorAll('li');
    const [prev, next] = el.querySelectorAll('nav > button');
    const counter = el.querySelector('header > em');

    let quoteStates = cycleElementStates(quotes, [
        [activeClass, 0],
    ], () => 0);

    window.addEventListener('resize', () => {
        matchHeights(quotes);
    }, { passive: true });
    prev.onclick = () => {
        const dec = (cur, total) => (cur - 1 + total) % total;

        quoteStates = cycleElementStates(quotes, quoteStates, dec);
        counter.innerHTML = `${quoteStates[0][1] + 1} / ${quotes.length}`;
    };
    next.onclick = () => {
        quoteStates = cycleElementStates(quotes, quoteStates);
        counter.innerHTML = `${quoteStates[0][1] + 1} / ${quotes.length}`;
    };

    matchHeights(quotes);
}
