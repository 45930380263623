import { isIOS, isSafari } from '../utilities';

const getRect = ({
    width,
    height,
    x = 0,
    y = 0,
    fill = 'white', // visible
}) => `<rect width="${width}" height="${height}" x="${x}" y="${y}" fill="${fill}" />`;

export default function PrimaryHero(el) {
    if (isIOS() || isSafari()) return;

    const intro = el.querySelector('header + p');
    const svg = el.querySelector('header + p + svg');
    const mask = el.querySelector('mask');

    let { offsetWidth: width, offsetHeight: height } = intro;
    let lineHeight = parseInt(window.getComputedStyle(intro).lineHeight, 10);
    let lines = Math.round(intro.offsetHeight / lineHeight);

    function handleScroll() {
        const { top } = intro.getBoundingClientRect();
        const { innerHeight } = window;
        const progress = (3.2 ** ((innerHeight - top) / innerHeight)) - 1.4;
        const reveals = [...Array(lines).keys()].map(i => {
            const min = (1 / lines) * i;
            const max = (1 / lines) * (i + 1);
            const lineProgress = Math.max(0, Math.min(1, (progress - min) / (max - min)));

            return getRect({
                width: width * (1 - lineProgress),
                height: lineHeight * 1.05, // lil extra for letters w/ hanging tails
                x: width * lineProgress,
                y: lineHeight * i,
                fill: 'gray', // semi-transparent
            });
        });

        mask.innerHTML = getRect({ width, height }) + reveals.join('');
        // const s = new XMLSerializer().serializeToString(svg);
        // const encodedData = window.btoa(unescape(encodeURIComponent(s)));
        // intro.style.maskImage = `url(data:image/svg+xml;base64,${encodedData})`;
    }
    function handleResize() {
        width = intro.offsetWidth;
        height = intro.offsetHeight;
        lineHeight = parseInt(window.getComputedStyle(intro).lineHeight, 10);
        lines = Math.round(intro.offsetHeight / lineHeight);

        handleScroll();
    }

    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize, { passive: true });

    svg.setAttribute('width', intro.offsetWidth);
    svg.setAttribute('height', intro.offsetHeight);
    svg.setAttribute('viewBox', `0 0 ${intro.offsetWidth} ${intro.offsetHeight}`);
    intro.style.maskImage = `url(${window.location.href}#${mask.id})`;
    handleScroll();
}
